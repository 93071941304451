.divider {
    width: 100%;
    height: 2px;
    background: #F5F5F5;
}

.productDetailsMain {
    display: flex;
    flex-direction: column;
    // padding: 24px;
    gap: 24px;
    color: black;
    padding: 24px;
}

.productDetailsHeaderDiv {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 18px;
    font-weight: 800;
    &-action-block{
        display: flex;
        gap: 15px;
        align-items: center; 
    }
}
.disabled-tracking-button{
    cursor: not-allowed;
}
.productDetailsNameTitle {
    font-size: 24px;
    font-weight: 600;
}

.productDetailsThumbnailImageDiv {
    width: 84px;
    height: 84px;

}

.productDetailsThumbnailImage {
    width: 100%;
    height: auto;

}

.productDetailsContentContainer {
    display: flex;
    flex-direction: column;
    gap: 35px;
    align-items: flex-start;
}

.productDetailsTagsContainer {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
}

// .productDetailsTagsContainer > div {
//     flex: 0 0 calc(50%);
// }

.productDetailsSingleTagDiv {
    display: flex;
    flex-direction: column;
    flex: 1;
    gap: 6px;
    // align-items: flex-start;
}

.tagTitleProductDetails {
    color: #737373;
    font-size: 16px;
    margin: 0px !important;
}

.tagValueProductDetails {
    color: black;
    font-size: 16px;
    font-weight: 500;
    margin: 0px !important;
}

.productDetailsDescriptionContainer{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.descriptionTitleProductDetails {
    margin-bottom: 20px;
    color: black;
    font-size: 18px;
    font-weight: 600;

}

.descriptionValueProductDetails {
    // padding-top: 20px;
    color: #737373;
    font-size: 12px;
    // max-height: 100px !important;
    max-width: 100%;
    overflow-y: hidden;
}

.VarReviewsTabsDiv {
    display: flex;
    align-items: flex-start;
    align-content: center;
    justify-content: flex-start;
    gap: 8px;
    width: 100%;
    height: 44px;
    padding: 5px;
    background-color: #F5F5F5;
    border-radius: 8px;
}

.tabUncheckedProductDetails {
    color: #D71513;
    padding-inline: 60px;
    padding-block: 8px;
    font-weight: 700;
    font-size: 14px;
    background: white;
    border-radius: 8px;
    text-align: center;
    cursor: pointer;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.071);

}

.tabCheckedProductDetails {
    color: #667085;
    font-weight: 700;
    font-size: 14px;
    padding-inline: 60px;
    padding-block: 8px;
    text-align: center;
    cursor: pointer;
}

.addProductButton {
    font-size: 16px;
    font-weight: 500;
    color: white;
    background: #D71513;
    padding-inline: 88px;
    padding-block: 12px;
    border: none;
    outline: none;
    border-radius: 8px;
}