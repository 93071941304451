/* appLoader */
.effect-1,
.effect-2,
.effect-3 {
  border-left: 3px solid #D71513 !important;
}
/* activeColor */
a.active {
  /* background: linear-gradient(118deg, #1390f8, #1390f8) !important; */
}

.OonlyCapitalized {
  text-transform: capitalize !important;
}

.white-space {
  white-space: break-spaces !important;
}
.WQNxq {
  min-height: 160px !important;
}
.customWidth {
  width: 375px !important;
}
.string-content {
  padding: 6px;
  height: 70px;
  width: 360px;
  overflow: scroll;
}

/* styles/CompanyInfo.module.css */
.infoGrid {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.infoRow {
  display: grid;
  grid-template-columns: minmax(200px, auto) 20px 1fr;
  align-items: start;
  min-height: 24px;
}

@media (max-width: 768px) {
  .infoRow {
    grid-template-columns: 150px 20px 1fr;
  }
}

/* Container for user type options */
.user-type-container {
  display: flex;
  gap: 2rem;
  margin-bottom: 1.5rem;
  justify-content: flex-start;
}

/* Label styles for the options */
.user-type-option {
  display: flex;
  align-items: center;
  cursor: pointer;
  position: relative;
  font-size: 1rem;
  font-weight: 500;
  color: #333;
}

/* Hidden radio input */
.user-type-option input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

/* Custom circular checkbox */
.user-type-option .circular-checkbox {
  width: 1.5rem;
  height: 1.5rem;
  border: 2px solid #ccc;
  border-radius: 50%;
  margin-right: 0.75rem;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease;
  background-color: #fff;
  position: relative;
}

/* Active state for the circular checkbox */
.user-type-option.active .circular-checkbox {
  border-color: #D71513;
  background-color: #D71513;
}

/* Checkmark inside the circle */
.user-type-option .circular-checkbox .checkmark {
  color: #fff;
  font-size: 1rem;
  font-weight: bold;
}

/* Hover effect */
.user-type-option:hover .circular-checkbox {
  border-color: #D71513;
}

.merchant-name {
  max-width: 200px;  /* Adjust width as needed */
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* Limit to 2 lines */
  -webkit-box-orient: vertical;
  word-break: break-word;
  overflow-wrap: break-word;
  white-space: normal;
}


.customBadge {
  display: inline-block;
  background-color:  #168952; /* Green background */
  color: white;
  font-size: 14px;
  font-weight: bold;
  padding: 4px 10px;
  border-radius: 4px;
}

.responsiveHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
}

.responsiveHeader .pageAddBrandDivFlexdataTable button {
  flex-shrink: 0;
}

.responsiveHeader .searchDivFlexdataTable {
  width: 200px; /* Fixed width */
  flex-shrink: 0;
}

@media (max-width: 768px) {
  .responsiveHeader {
    flex-direction: column;
    align-items: stretch;
  }

  .responsiveHeader .pageAddBrandDivFlexdataTable,
  .responsiveHeader .searchDivFlexdataTable {
    width: 100%;
  }

  .responsiveHeader .pageAddBrandDivFlexdataTable button {
    width: 100%;
  }
}










